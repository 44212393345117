const applicationVariables = {
    publishedDate: process.env.REACT_APP_PUBLISHED_DATE ?? new Date().toLocaleDateString('en-GB', { day: '2-digit', month: 'short', year: '2-digit'}),
    publishedVersion: process.env.REACT_APP_PUBLISHED_VERSION ?? 'ALPHA',
    sheepfairUrl: process.env.REACT_APP_SHEEPFAIR_URL as string,
    gMapsDirUrl: process.env.REACT_APP_GMAPS_SCC_DIR_URL as string,
    osmWaitListUrl: process.env.REACT_APP_OSM_WAIT_LIST_URL as string,
    scoutShopUrl: process.env.REACT_APP_SCOUT_SHOP_URL as string,
    uniformVendorUrl: process.env.REACT_APP_UNIFORM_VENDOR_URL as string,
}

export default applicationVariables;