import React from 'react';
import { MediaItem } from '../../../../types/general';
import { css } from 'aphrodite';
import { commonStyles } from '../../../../styles/common.styles';
import homeIntroductionStyles from '../../../home/introduction/home-introduction.styles';
import { useScreenSizeContext } from '../../../../util/screensize.context';
import sectionIntroductionStyles from './section-introduction.styles';

export type SectionIntroductionProps = {
  heading?: string;
  image: MediaItem;
  children: React.ReactNode;
};

const SectionIntroduction = ({
  heading,
  image,
  children,
}: SectionIntroductionProps) => {
  const { isScreenSmallOrLess, isScreenXSmall } = useScreenSizeContext();

  return (
    <div
      className={css(
        commonStyles.screenMargin,
        commonStyles.flex,
        commonStyles.centreHorizontal,
      )}
    >
      <div
        className={css(
          commonStyles.flex,
          sectionIntroductionStyles.contentContainer,
        )}
      >
        <div
          className={css(
            commonStyles.flex,
            commonStyles.flexColumn,
            commonStyles.gapXXXSmall,
          )}
        >
          {heading && (
            <h4
              className={css(
                homeIntroductionStyles.introductionText,
                isScreenXSmall
                  ? homeIntroductionStyles.introductionTextMobile
                  : homeIntroductionStyles.introductionTextLarger,
              )}
            >
              <b>{heading}</b>
            </h4>
          )}
          {children}
        </div>
        <div
          className={css(
            commonStyles.smooth,
            commonStyles.flex,
            commonStyles.flexGrow,
            commonStyles.centreHorizontal,
            isScreenSmallOrLess
              ? homeIntroductionStyles.imageContainerMobile
              : homeIntroductionStyles.imageContainer,
          )}
        >
          <img
            className={css(
              commonStyles.smooth,
              sectionIntroductionStyles.image,
            )}
            src={image.src}
            alt={image.alt}
          />
        </div>
      </div>
    </div>
  );
};

export default SectionIntroduction;
