import { css } from 'aphrodite';
import React from 'react';
import Button, { ButtonVariant } from '../../../../../components/button/button';
import { CustomIconName } from '../../../../../components/custom-icon/custom-icons';
import { commonStyles } from '../../../../../styles/common.styles';
import { Page } from '../../../../../types/navigation';
import { Section } from '../../../../../types/general';

type MovingOnData = {
  nextSection?: string;
  customText?: string;
  buttonLabel?: string;
  buttonVariant: ButtonVariant;
  age: string;
  link: Page;
};

const movingOnData: Record<Section, MovingOnData> = {
  Squirrels: {
    nextSection: 'Beaver',
    age: '6',
    link: Page.Beavers,
    buttonVariant: 'primary-blue',
  },
  Beavers: {
    nextSection: 'Cub',
    age: '8',
    link: Page.Cubs,
    buttonVariant: 'primary-green',
  },
  Cubs: {
    nextSection: 'Scout',
    age: '10½',
    link: Page.Scouts,
    buttonVariant: 'primary',
  },
  Scouts: {
    nextSection: 'Explorer',
    age: '14',
    link: Page.Explorers,
    buttonVariant: 'primary',
  },
  Explorers: {
    nextSection: 'Network',
    age: '18',
    link: Page.Network,
    buttonVariant: 'primary',
  },
  Network: {
    customText: `Once Network Members reach the age of 25, they will be able to take on a leadership role within any section of the group, if they don't already have one. Speak to the District Scout Network Commissioner, who will be able to advise what opportunities are available.`,
    age: '25',
    link: Page.Network,
    buttonLabel: 'Learn more about volunteering',
    buttonVariant: 'primary',
  },
};

export type SectionDetailsMovingOnProps = {
  section: Section;
  isVisible?: boolean;
};

const SectionDetailsMovingOn = ({
  section,
  isVisible,
}: SectionDetailsMovingOnProps) => {
  const data = React.useMemo(() => movingOnData[section], [section]);

  return (
    <>
      <p className={css(commonStyles.noMarginTop)}>
        {data.nextSection
          ? `${section} will be able to move up to the ${data.nextSection} section once they reach
        the age of ${data.age}. Details of our ${data.nextSection} section can be found below.`
          : data.customText}
      </p>
      <Button
        label={
          data.nextSection
            ? `Move up to ${data.nextSection === 'Network' ? 'Network' : `${data.nextSection}s`}`
            : (data.buttonLabel ?? 'Learn More')
        }
        icon={CustomIconName.ArrowCircleRight}
        fontSize='20px'
        borderOnHover
        variant={data.buttonVariant}
        link={data.link}
        focusable={isVisible}
      />
    </>
  );
};

export default SectionDetailsMovingOn;
