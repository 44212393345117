import { css } from 'aphrodite';
import React from 'react';
import homeSheepfairStyles from './home-sheepfair.styles';
import { commonStyles } from '../../../styles/common.styles';
import { media } from '../../../util/media';
import Button from '../../../components/button/button';
import { useScreenSizeContext } from '../../../util/screensize.context';
import { theme } from '../../../styles/theme';
import { CustomIconName } from '../../../components/custom-icon/custom-icons';
import applicationVariables from '../../../util/variables';

const HomeSheepfair = () => {
  const { isScreenSmallOrLess, isScreenXLarge } = useScreenSizeContext();

  return (
    <div className={css(commonStyles.screenPadding)}>
      <h2
        className={css(
          commonStyles.pageSectionHeading,
          commonStyles.noMargin,
          !isScreenSmallOrLess && commonStyles.textAlignRight,
        )}
      >
        Sheepfair Community Centre
      </h2>
      <div
        className={css(
          commonStyles.flex,
          commonStyles.marginTopMedium,
          isScreenSmallOrLess && commonStyles.flexColumn,
          !isScreenSmallOrLess && commonStyles.gapMedium,
        )}
      >
        <div
          className={css(
            commonStyles.flex,
            commonStyles.centreHorizontal,
            isScreenSmallOrLess
              ? homeSheepfairStyles.smallImageContainer
              : homeSheepfairStyles.largeImageContainer,
          )}
        >
          <img
            src={media.images.index.sheepfair.src}
            width={'100%'}
            height={'100%'}
            className={css(homeSheepfairStyles.image)}
            alt={media.images.index.sheepfair.alt}
          />
        </div>
        <div
          className={css(
            commonStyles.flex,
            commonStyles.flexColumn,
            !isScreenSmallOrLess && homeSheepfairStyles.contentContainerLarge,
          )}
        >
          <h3
            style={{
              fontSize: isScreenXLarge
                ? 'calc(1vw + 1vh)'
                : isScreenSmallOrLess
                  ? '26px'
                  : 'calc(1.5vw + 1.5vh)',
              fontWeight: 800,
              marginTop: isScreenSmallOrLess ? theme.size.xlarge : 0,
            }}
          >
            Want to hire our meeting place?
          </h3>
          <p>
            Sheepfair Community Centre is available for hire and can cater for a
            variety of events, including parties and celebrations, as well as
            business events.
          </p>
          <p>
            <b>Visit their website for more details.</b>
          </p>
          <div className={css(commonStyles.marginTopLarge)}>
            <Button
              label='Visit Sheepfair'
              variant='primary'
              icon={CustomIconName.ArrowCircleRight}
              fontSize='22px'
              bold
              borderOnHover
              link={applicationVariables.sheepfairUrl}
              linkInNewTab
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSheepfair;
