import React from 'react';
import { css } from 'aphrodite';
import { useScreenSizeContext } from '../../util/screensize.context';
import headerStyles from './header.styles';
import { commonStyles } from '../../styles/common.styles';
import { MediaItem } from '../../types/general';
import generalUtil from '../../util/general';

export type HeaderProps = {
  image: MediaItem | MediaItem[];
  title?: string;
  subtitle?: string;
  titleDesc?: string;
  subImage?: string;
  subImageAlt?: string;
  alwaysFullScreen?: boolean;
  backgroundPosition?: string;
  children?: React.ReactNode;
};

const Header = ({
  image,
  title,
  subtitle,
  titleDesc,
  subImage,
  subImageAlt,
  alwaysFullScreen,
  backgroundPosition = 'center',
  children,
}: HeaderProps) => {
  const { isScreenLargeOrMore, isScreenXSmall, isScreenMediumOrLess } =
    useScreenSizeContext();

  const getSubtitleSize = () => {
    if (isScreenLargeOrMore) {
      return '2.2vw';
    } else if (isScreenXSmall) {
      return '4.5vw';
    } else if (isScreenMediumOrLess) {
      return '4vw';
    } else {
      return '3.5vw';
    }
  };

  const subtitleFontSize = React.useMemo(
    () => getSubtitleSize(),
    [isScreenLargeOrMore, isScreenXSmall, isScreenMediumOrLess],
  );

  const bgImage = React.useMemo(
    () =>
      Array.isArray(image) ? generalUtil.getRandomEntryFromArray(image) : image,
    [image],
  );

  return (
    <div
      className={css(
        headerStyles.container,
        isScreenXSmall && !alwaysFullScreen
          ? headerStyles.halfScreenHeight
          : headerStyles.fullScreenHeight,
      )}
      style={{
        backgroundImage: `url(${bgImage.src})`,
        backgroundPosition,
      }}
      aria-label={bgImage.alt}
    >
      {children && <>{children}</>}
      {!children && (
        <div className={css(headerStyles.contentContainer)}>
          <div
            className={css(
              commonStyles.flex,
              commonStyles.spaceBetween,
              headerStyles.contentSubContainer,
            )}
          >
            <div>
              {title && (
                <h1
                  className={css(commonStyles.noMargin)}
                  style={{
                    fontSize: isScreenLargeOrMore ? '6vw' : '10vw',
                  }}
                >
                  {title}
                </h1>
              )}
              {titleDesc && (
                <h3
                  className={css(commonStyles.noMargin, headerStyles.titleDesc)}
                >
                  {titleDesc}
                </h3>
              )}
              {subtitle && (
                <h2
                  className={css(commonStyles.noMargin)}
                  style={{
                    fontSize: subtitleFontSize,
                  }}
                >
                  {subtitle}
                </h2>
              )}
            </div>
            <div className={css(commonStyles.flex, commonStyles.justifyEnd)}>
              {subImage && (
                <img src={subImage} width={'20%'} alt={subImageAlt} />
              )}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Header;
