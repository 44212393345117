import { css } from 'aphrodite';
import React from 'react';
import homeVolunteerStyles from './home-volunteer.styles';
import { commonStyles } from '../../../styles/common.styles';
import { media } from '../../../util/media';
import Button from '../../../components/button/button';
import { Page } from '../../../types/navigation';
import { useScreenSizeContext } from '../../../util/screensize.context';
import { CustomIconName } from '../../../components/custom-icon/custom-icons';

const HomeVolunteer = () => {
  const { isScreenSmallOrLess, isScreenSmall } = useScreenSizeContext();

  return (
    <div
      className={css(
        homeVolunteerStyles.sectionContainer,
        commonStyles.screenPadding,
        commonStyles.textWhite,
      )}
    >
      <h2
        className={css(
          commonStyles.pageSectionHeading,
          commonStyles.noMargin,
          isScreenSmall && homeVolunteerStyles.headingSmall,
        )}
      >
        You look like a role model
      </h2>
      <div
        className={css(
          commonStyles.flex,
          commonStyles.marginTopMedium,
          isScreenSmallOrLess && commonStyles.flexColumn,
          !isScreenSmallOrLess && commonStyles.gapMedium,
        )}
      >
        {isScreenSmallOrLess && (
          <div
            className={css(
              commonStyles.flex,
              commonStyles.centreHorizontal,
              commonStyles.marginBottomMedium,
              homeVolunteerStyles.imageContainer,
            )}
          >
            <img
              src={media.images.index.volunteer.src}
              width={'100%'}
              height={'100%'}
              className={css(homeVolunteerStyles.image)}
              alt={media.images.index.volunteer.alt}
            />
          </div>
        )}
        <div
          className={css(
            commonStyles.flex,
            commonStyles.flexColumn,
            !isScreenSmallOrLess && homeVolunteerStyles.contentContainerLarge,
          )}
        >
          <p>
            Young people are the future. They&apos;re the <b>doers</b>, the{' '}
            <b>dreamers</b> and the <b>give-it-a-goers</b>.
          </p>
          <p>
            As a volunteer you can <b>put your skills to use</b> and learn new
            ones, while giving something back to your local community.
          </p>
          <p>
            And with <b>full support and training</b>, you can volunteer in a
            way that suits you.
          </p>
          <div className={css(commonStyles.marginTopLarge)}>
            <Button
              label='Learn More'
              variant='secondary-blue'
              icon={CustomIconName.ArrowCircleRight}
              fontSize='22px'
              bold
              borderOnHover
              link={Page.Volunteer}
            />
          </div>
        </div>
        {!isScreenSmallOrLess && (
          <div
            className={css(
              commonStyles.flex,
              commonStyles.centreHorizontal,
              homeVolunteerStyles.largeImageContainer,
            )}
          >
            <img
              src={media.images.index.volunteer.src}
              width={'100%'}
              height={'100%'}
              className={css(homeVolunteerStyles.image)}
              alt={media.images.index.volunteer.alt}
            />
          </div>
        )}
      </div>
    </div>
  );
};

export default HomeVolunteer;
