import { css } from 'aphrodite';
import React from 'react';
import { commonStyles } from '../../../styles/common.styles';
import homeSectionsStyles from './home-sections.styles';
import SectionCard from '../../../components/section-card/section/section-card';
import { useScreenSizeContext } from '../../../util/screensize.context';
import { media } from '../../../util/media';

const HomeSections = () => {
  const {
    isScreenSmall,
    isScreenXLarge,
    isScreenXSmall,
    isScreenMediumOrLess,
    screenWidth,
  } = useScreenSizeContext();

  const showIllustration = React.useMemo(
    () => screenWidth >= 770,
    [screenWidth],
  );

  return (
    <div
      className={css(
        showIllustration
          ? homeSectionsStyles.sectionContainer
          : commonStyles.screenMargin,
      )}
    >
      <div
        className={css(
          commonStyles.flex,
          !showIllustration
            ? commonStyles.centreHorizontal
            : commonStyles.spaceBetween,
          commonStyles.centreVertical,
        )}
      >
        <div
          className={css(
            commonStyles.flex,
            commonStyles.flexColumn,
            homeSectionsStyles.heading,
          )}
        >
          <h2
            className={css(
              commonStyles.pageSectionHeading,
              commonStyles.noMargin,
            )}
          >
            Start Your Journey
          </h2>
          <img
            src={media.images.index.underline.src}
            alt={media.images.index.underline.alt}
            width={'100%'}
          />
        </div>
        {showIllustration && (
          <div
            className={css(
              homeSectionsStyles.doodleContainer,
              isScreenMediumOrLess
                ? homeSectionsStyles.doodleContainerSmall
                : homeSectionsStyles.doodleContainerLarge,
            )}
          >
            <img
              src={media.images.index.startYourJourney.src}
              alt={media.images.index.startYourJourney.alt}
              width={'100%'}
            />
          </div>
        )}
      </div>
      <div
        className={
          isScreenXLarge
            ? css(commonStyles.flex, commonStyles.centreHorizontal)
            : undefined
        }
      >
        <div
          className={css(
            commonStyles.flex,
            commonStyles.flexColumn,
            commonStyles.gapMedium,
            commonStyles.centreVertical,
            homeSectionsStyles.cardsContainer,
          )}
        >
          <div
            className={css(
              commonStyles.flex,
              commonStyles.centreVertical,
              commonStyles.fullWidth,
              isScreenXLarge
                ? commonStyles.gapXXLarge
                : isScreenXSmall
                  ? commonStyles.gapMedium
                  : commonStyles.spaceEvenly,
              isScreenXSmall && commonStyles.flexColumn,
            )}
          >
            <SectionCard variant='Squirrels' />
            <SectionCard variant='Beavers' />
            {!isScreenSmall && <SectionCard variant='Cubs' />}
          </div>
          {isScreenSmall && (
            <div
              className={css(
                commonStyles.flex,
                commonStyles.centreVertical,
                commonStyles.fullWidth,
                isScreenXLarge
                  ? commonStyles.gapXXLarge
                  : isScreenXSmall
                    ? commonStyles.gapMedium
                    : commonStyles.spaceEvenly,
                isScreenXSmall && commonStyles.flexColumn,
              )}
            >
              <SectionCard variant='Cubs' />
              <SectionCard variant='Scouts' />
            </div>
          )}
          <div
            className={css(
              commonStyles.flex,
              commonStyles.centreVertical,
              commonStyles.fullWidth,
              isScreenXLarge
                ? commonStyles.gapXXLarge
                : isScreenXSmall
                  ? commonStyles.gapMedium
                  : commonStyles.spaceEvenly,

              isScreenXSmall && commonStyles.flexColumn,
            )}
          >
            {!isScreenSmall && <SectionCard variant='Scouts' />}
            <SectionCard variant='Explorers' />
            <SectionCard variant='Network' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeSections;
