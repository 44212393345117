import React from 'react';
import ReactDOM from 'react-dom/client';
import './styles/index.css';
import '@fontsource-variable/nunito-sans';
import App from './App';
import reportWebVitals from './util/reportWebVitals';
import { ScreenSizeProvider } from './util/screensize.context';
import { SessionContextProvider } from './util/session.context';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <SessionContextProvider>
      <ScreenSizeProvider>
        <App />
      </ScreenSizeProvider>
    </SessionContextProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// TODO: Install Google Analytics
reportWebVitals();
