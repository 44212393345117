import React from 'react';
import { css } from 'aphrodite';
import { commonStyles } from '../../styles/common.styles';
import getUnderlineLinkStyles from './underline-link.styles';
import { theme } from '../../styles/theme';

export type UnderlineLinkProps = {
  text: string;
  link: string;
  linkInNewTab?: boolean;
  textColour?: string;
  inline?: boolean;
};

const UnderlineLink = ({
  text,
  link,
  linkInNewTab = false,
  textColour = theme.colour.black,
  inline = false,
}: UnderlineLinkProps) => {
  const styles = React.useMemo(
    () => getUnderlineLinkStyles(textColour),
    [textColour],
  );

  return inline ? (
    <span
      className={css(styles.underlineAnimationText, commonStyles.noWrapText)}
    >
      <a
        className={css(commonStyles.nativeReset)}
        href={link}
        target={linkInNewTab ? '_blank' : '_self'}
        rel='noreferrer'
      >
        {text}
      </a>
    </span>
  ) : (
    <div>
      <a
        className={css(
          commonStyles.nativeReset,
          styles.underlineAnimationText,
          commonStyles.noWrapText,
        )}
        href={link}
        target={linkInNewTab ? '_blank' : '_self'}
        rel='noreferrer'
      >
        {text}
      </a>
    </div>
  );
};

export default UnderlineLink;
