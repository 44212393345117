import { StyleSheet } from 'aphrodite';
import { theme } from '../../styles/theme';

const headerStyles = StyleSheet.create({
  container: {
    backgroundSize: 'cover',
    position: 'relative',
    transition: 'all 0.6s ease-in-out',
  },
  fullScreenHeight: {
    height: '100vh',
    minHeight: '40vw',
    overflow: 'hidden',
  },
  halfScreenHeight: {
    height: '50vh',
    minHeight: '40vw',
    overflow: 'hidden',
  },
  contentContainer: {
    color: theme.colour.white,
    zIndex: 10,
    position: 'absolute',
    bottom: 0,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
    width: '100%',
    marginBottom: '6vw',
  },
  contentSubContainer: {
    width: '80%',
  },
  titleDesc: {
    fontSize: '1.5vw',
    fontWeight: 500,
  },
});

export default headerStyles;
