import React from 'react';
import Header from '../../../components/header/header';
import { media } from '../../../util/media';
import generalUtil from '../../../util/general';
import SectionIntroduction from '../shared/introduction/section-introduction';
import SectionDetails from '../shared/details/section-details';
import { SectionDetailData } from '../../../types/general';
import { css } from 'aphrodite';
import { commonStyles } from '../../../styles/common.styles';
import SectionDetailsBadgePlacement from '../shared/details/badge-placement/section-details-badge-placement';
import SectionDetailsPromise from '../shared/details/promise/section-details-promise';
import SectionDetailsSafeguarding from '../shared/details/safeguarding/section-details-safeguarding';
import SectionDetailsMoreInformation from '../shared/details/more-information/section-details-more-information';
import SectionDetailsWhereWhen from '../shared/details/where-when/section-details-where-when';
import SectionDetailsMovingOn from '../shared/details/moving-on/section-details-moving-on';
import SectionContact from '../shared/contact/section-contact';
import Button from '../../../components/button/button';
import { CustomIconName } from '../../../components/custom-icon/custom-icons';
import { useScreenSizeContext } from '../../../util/screensize.context';
import applicationVariables from '../../../util/variables';

const Beavers = () => {
  const [openCardIndexes, setOpenCardIndexes] = React.useState<number[]>([]);

  const { isScreenXSmall } = useScreenSizeContext();

  const detailData: SectionDetailData = [
    {
      question: 'What do Beavers do?',
      answer: (
        <div
          className={css(
            commonStyles.flex,
            commonStyles.flexColumn,
            commonStyles.gapMedium,
          )}
        >
          <p className={css(commonStyles.noMargin)}>
            Being a Beaver is all about growing and learning in small but mighty
            ways. Here are some of the things you&apos;ll get up to with your
            new friends.
          </p>
          <div>
            <b className={css(commonStyles.text)}>
              Exploring the great outdoors
            </b>
            <p
              className={css(
                commonStyles.marginTopXXXSmall,
                commonStyles.noMarginBottom,
              )}
            >
              You&apos;ll spend lots of time outside with your Colony. Together,
              you might build a den, or go on a trip to the seaside, or host a
              Beaver sleepover beneath the stars. And even though you might not
              be ready to climb Mount Everest just yet, you&apos;re guaranteed
              to have plenty of adventures on your own doorstep, because being a
              Beaver is all about making the most of what you have, wherever and
              whoever you are.
            </p>
          </div>
          <div>
            <b className={css(commonStyles.text)}>
              Trying new activities and learning new things
            </b>
            <p
              className={css(
                commonStyles.marginTopXXXSmall,
                commonStyles.noMarginBottom,
              )}
            >
              Going to Beavers is very different from going to school. Instead
              of learning from books, you&apos;ll figure the world out by
              exploring, playing and doing.
            </p>
            <p className={css(commonStyles.noMarginBottom)}>
              The most important skills you&apos;ll learn at Beavers are the
              ones that will make you feel super strong standing on your own two
              feet. We call these character skills. They include things like
              integrity - which means being honest and doing what you think is
              right - and initiative - which means knowing how to take the lead
              on something without being asked. It&apos;s all about having the
              courage to try new things and learn from them.
            </p>
          </div>
          <div>
            <b className={css(commonStyles.text)}>Helping others</b>
            <p
              className={css(
                commonStyles.marginTopXXXSmall,
                commonStyles.noMarginBottom,
              )}
            >
              Beavers work as a team to help other people, in their local
              communities and beyond. Whether they&apos;re changing the whole
              world or helping a friend take the leap to try something new on a
              rainy Wednesday night, they always lend a hand.
            </p>
          </div>
        </div>
      ),
    },
    {
      question: 'Who can join Beavers?',
      answer: `Anyone between the ages of 6 and 8, whether they've been part of our younger section, Squirrels, or not. Here at Scouts, we're open to people of all genders, backgrounds, and faiths (including people of no faith). We're committed to reaching out to new and different families and communities and including people who have never tried Scouts before.`,
    },
    {
      question: 'Where & when do Beavers meet?',
      answer: (
        <SectionDetailsWhereWhen
          fullSectionName='Beavers'
          dateTime='Wednesdays, from 18:15 - 19:30'
          isVisible
        />
      ),
    },
    {
      question: 'Do Beavers have a uniform?',
      answer: (
        <>
          <p className={css(commonStyles.noMarginTop)}>
            The Beavers uniform consists of a blue sweatshirt with your badges
            sewn on and a coloured scarf or &quot;necker&quot; to represent 2nd
            Rugeley. There are lots of other optional accessories you can wear
            such as hats, hoodies, navy blue trousers or shorts, if you&apos;d
            like to.
          </p>
          <p>
            Wearing a uniform is comfy and practical. It means you can run
            around and get messy without ruining your other clothes. It makes
            you feel part of a team. It means no one feels uncomfortable or left
            out. And it gives you a place to show off all the brilliant badges
            you earn.
          </p>
          <p>
            Uniform can either be bought from the official Scout Store or from a
            local supplier (such as Uniforms Plus). If you&apos;re stuck, please
            feel free to ask the section leader to tell you more about what to
            buy and where to buy it. If cost is an issue, they will be more than
            happy to help.
          </p>
          <div
            className={css(
              commonStyles.flex,
              isScreenXSmall ? commonStyles.gapSmall : commonStyles.gapLarge,
              isScreenXSmall && commonStyles.flexColumn,
            )}
          >
            <Button
              label='Scout Shop'
              fontSize='20px'
              icon={CustomIconName.ArrowCircleRight}
              borderOnHover
              link={applicationVariables.scoutShopUrl}
              linkInNewTab
            />
            <Button
              label='Uniforms Plus'
              fontSize='20px'
              icon={CustomIconName.ArrowCircleRight}
              borderOnHover
              link={applicationVariables.uniformVendorUrl}
              linkInNewTab
            />
          </div>
        </>
      ),
    },
    {
      question: 'Where do badges go?',
      answer: (
        <SectionDetailsBadgePlacement
          section='Beavers'
          isVisible={openCardIndexes.includes(1)}
        />
      ),
    },
    {
      question: 'What is an investiture?',
      answer: (
        <>
          <p className={css(commonStyles.noMarginTop)}>
            As well as enjoying plenty of adventures, being a Beaver is about
            exploring who you are and what you stand for. These are big ideas,
            and when you join the Colony, you&apos;ll start thinking about them
            by making a promise. A promise is a set of words that mean something
            to you, which you try to follow everyday.
          </p>
          <p className={css(commonStyles.noMarginBottom)}>
            Making the promise is a big celebration within the Colony. Every
            time a new Beaver decides to join, they chat through their promise
            with their leader before saying it out loud in front of their fellow
            Beavers. Family and friends might come along to see this, too. Doing
            this is called being &quot;invested&quot; into Beavers, and it
            usually takes place once you&apos;ve had a few weeks to settle in.
          </p>
        </>
      ),
    },
    {
      question: 'What is the Beaver Promise?',
      answer: (
        <SectionDetailsPromise
          section='Beavers'
          introduction='Everyone is unique but there are some things all Beavers agree on - such as treating everyone with kindness and promising to do their best. Depending on their own beliefs, they might also promise to live by their faith. Beavers will choose the promise that best suits them.'
          data={[
            {
              variant: 'Atheist or of no faith background',
              promise:
                'I promise to do my best to be kind and helpful and to love our world.',
            },
            {
              variant: 'Buddhist',
              promise:
                'I promise to do my best to be kind and helpful and to act with love towards everyone.',
            },
            {
              variant: 'Christian',
              promise:
                'I promise to do my best to be kind and helpful and to love the world.',
            },
            {
              variant: 'Hindu',
              promise:
                'I promise to do my best to be kind and helpful and to love the world.',
            },
            {
              variant: 'Humanist',
              promise:
                'I promise to do my best to be kind and helpful and to love our world.',
            },
            {
              variant: 'Jewish',
              promise:
                'I promise to do my best to be kind and helpful and to love God.',
            },
            {
              variant: 'Muslim',
              promise:
                'I promise to do my best to be kind and helpful and to love Allah.',
            },
            {
              variant: 'Sikh',
              promise:
                'I promise to do my best and to be kind and helpful and to love Waheguru.',
            },
          ]}
        />
      ),
    },
    {
      question: `I'm concerned about safety/safeguarding`,
      answer: (
        <SectionDetailsSafeguarding isVisible={openCardIndexes.includes(4)}>
          <p>
            All of our leaders are trained volunteers, working to make sure
            Scouting is safe and open to all. The programme delivered is
            specifically designed to be accessible for all 6 and 7 year olds.
          </p>
        </SectionDetailsSafeguarding>
      ),
    },
    {
      question: 'What happens after Beavers?',
      answer: (
        <SectionDetailsMovingOn
          section='Beavers'
          isVisible={openCardIndexes.includes(5)}
        />
      ),
    },
    {
      question: `I'd like more information`,
      answer: (
        <SectionDetailsMoreInformation
          section='Beavers'
          isVisible={openCardIndexes.includes(6)}
        />
      ),
    },
  ];

  const bgImage = React.useMemo(
    () =>
      generalUtil.getRandomEntryFromArray(media.images.sections.beavers.header),
    [],
  );

  return (
    <>
      <Header
        image={bgImage}
        title='Beavers'
        subtitle='Ages 6-8'
        backgroundPosition={bgImage.pos}
      />
      <SectionIntroduction
        heading='Try new things. Make new friends. Joining Beavers is just the beginning of your big adventure.'
        image={media.images.sections.beavers.introduction}
      >
        <>
          <p>
            Every week, they gather in groups called Beaver Colonies to hop,
            skip and jump their way through lots of different games and
            activities - achieving anything they set their minds to, and having
            lots of fun along the way.
          </p>
          <p>
            From making new friends to mastering new skills, Beavers step foot
            on an adventure of opportunity. Beavers help others and make a
            difference, both on their doorsteps and beyond, learning the
            importance of friendship and kindness.
          </p>
          <p>
            Beavers are naturally curious about the world around them, and our
            programme will help them learn about their surroundings whilst
            having a bucket load of fun!
          </p>
          <p>
            Sound like fun? That&apos;s because it is. All that&apos;s missing
            is you.
          </p>
        </>
      </SectionIntroduction>
      <SectionDetails
        data={detailData}
        formation='pyramid'
        onCardChange={setOpenCardIndexes}
      />
      <SectionContact section='Beavers' />
    </>
  );
};

export default Beavers;
