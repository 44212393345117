import { css } from 'aphrodite';
import React from 'react';
import Button from '../../../../../components/button/button';
import { CustomIconName } from '../../../../../components/custom-icon/custom-icons';
import { commonStyles } from '../../../../../styles/common.styles';
import { useScreenSizeContext } from '../../../../../util/screensize.context';
import { Section } from '../../../../../types/general';

export type SectionDetailsMoreInformationProps = {
  section: Section;
  isVisible?: boolean;
};

const SectionDetailsMoreInformation = ({
  section,
  isVisible,
}: SectionDetailsMoreInformationProps) => {
  const { isScreenSmallOrLess } = useScreenSizeContext();
  return (
    <>
      <p className={css(commonStyles.noMarginTop)}>
        More information about the{' '}
        {section === 'Network' ? section : section.slice(0, -1)} section and the
        kinds of activities{' '}
        {section === 'Network' ? 'Network Members' : section} do can be found on
        the Official Scout website. Alternatively, feel free to contact the{' '}
        {section === 'Network'
          ? 'District Scout Network Commissioner'
          : 'Section Leader'}
        , whose details are given at the bottom of this page.
      </p>
      <div
        className={css(
          commonStyles.flex,
          isScreenSmallOrLess ? commonStyles.gapSmall : commonStyles.gapLarge,
          isScreenSmallOrLess && commonStyles.flexColumn,
        )}
      >
        <Button
          label='Read more at scouts.org.uk'
          fontSize='20px'
          icon={CustomIconName.ArrowCircleRight}
          borderOnHover
          link={`https://www.scouts.org.uk/${section.toLowerCase()}`}
          linkInNewTab
          focusable={isVisible}
        />
        <Button
          label={`Contact ${section === 'Network' ? 'DSNC' : 'Section Leader'}`}
          fontSize='20px'
          borderOnHover
          icon={CustomIconName.ArrowCircleRight}
          focusable={isVisible}
          onClick={() =>
            document
              .getElementById('section-contact')
              ?.scrollIntoView({ block: 'center', behavior: 'smooth' })
          }
        />
      </div>
    </>
  );
};

export default SectionDetailsMoreInformation;
