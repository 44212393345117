import { StyleSheet } from 'aphrodite';
import { theme } from './theme';

export const commonStyles = StyleSheet.create({
  nativeReset: {
    padding: '0px',
    border: 'none',
    font: 'inherit',
    color: 'inherit',
    backgroundColor: 'transparent',
    cursor: 'pointer',
    textDecoration: 'none',
    textAlign: 'left',
  },
  flex: {
    display: 'flex',
  },
  flexColumn: {
    flexDirection: 'column',
  },
  justifyEnd: {
    justifyContent: 'flex-end',
  },
  alignEnd: {
    alignItems: 'flex-end',
  },
  spaceBetween: {
    justifyContent: 'space-between',
  },
  spaceEvenly: {
    justifyContent: 'space-evenly',
  },
  spaceAround: {
    justifyContent: 'space-around',
  },
  centreHorizontal: {
    justifyContent: 'center',
  },
  centreVertical: {
    alignItems: 'center',
  },
  bold: {
    fontWeight: 'bold',
  },
  boldestText: {
    fontWeight: 900,
  },
  clickable: {
    cursor: 'pointer',
  },
  gapXXXSmall: {
    gap: theme.size.xxxsmall,
  },
  gapXXSmall: {
    gap: theme.size.xxsmall,
  },
  gapXSmall: {
    gap: theme.size.xsmall,
  },
  gapSmall: {
    gap: theme.size.small,
  },
  gapMedium: {
    gap: theme.size.medium,
  },
  gapLarge: {
    gap: theme.size.large,
  },
  gapXLarge: {
    gap: theme.size.xlarge,
  },
  gapXXLarge: {
    gap: theme.size.xxlarge,
  },
  fullWidth: {
    width: '100%',
  },
  fullHeight: {
    height: '100%',
  },
  marginTopXXXSmall: {
    marginTop: theme.size.xxxsmall,
  },
  marginTopXSmall: {
    marginTop: theme.size.xsmall,
  },
  marginTopSmall: {
    marginTop: theme.size.small,
  },
  marginTopMedium: {
    marginTop: theme.size.medium,
  },
  marginTopLarge: {
    marginTop: theme.size.large,
  },
  marginBottomXXXSmall: {
    marginBottom: theme.size.xxxsmall,
  },
  marginBottomXXSmall: {
    marginBottom: theme.size.xxsmall,
  },
  marginBottomXSmall: {
    marginBottom: theme.size.xsmall,
  },
  marginBottomMedium: {
    marginBottom: theme.size.medium,
  },
  marginBottomLarge: {
    marginBottom: theme.size.large,
  },
  text: {
    fontSize: '20px',
  },
  textWhite: {
    color: theme.colour.white,
  },
  largeText: {
    fontSize: '26px',
  },
  noMargin: {
    margin: 0,
  },
  noMarginTop: {
    marginTop: 0,
  },
  noMarginBottom: {
    marginBottom: 0,
  },
  noWrapText: {
    textWrap: 'nowrap',
  },
  textAlignRight: {
    textAlign: 'right',
  },
  relative: {
    position: 'relative',
  },
  flexGrow: {
    flexGrow: 1,
  },
  shadow: {
    boxShadow: `10px 10px 26px -10px rgba(0,0,0,0.75)`,
  },
  screenMargin: {
    margin: '4vw',
    [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
      margin: '8vw 4vw',
    },
  },
  screenPadding: {
    padding: '4vw',
    [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
      padding: '8vw 4vw',
    },
  },
  pageSectionHeading: {
    fontSize: 'calc(2.5vw + 2.5vh)',
    fontWeight: 800,
    [`@media (max-width: ${theme.breakpoints.medium}px)`]: {
      fontSize: 'calc(3.5vw + 3.5vh)',
    },
    marginBottom: 0,
  },
  opacityHover: {
    transition: 'opacity 0.2s ease-in-out',
    opacity: 1,
    ':hover': {
      opacity: 0.6,
    },
  },
  smooth: {
    transition: 'all 0.5s ease-in-out',
  },
  maxScreenWidth: {
    maxWidth: `${theme.breakpoints.xlarge}px`,
  },
});
