import React from 'react';
import { Outlet } from 'react-router-dom';
import MobileNav from './mobile-nav/mobile-nav';
import Footer from './footer/footer';
import DesktopNav from './desktop-nav/desktop-nav';
import { useScreenSizeContext } from '../../util/screensize.context';

const Layout = () => {
  const { isScreenLargeOrMore } = useScreenSizeContext();
  return (
    <>
      {!isScreenLargeOrMore && <MobileNav />}
      <DesktopNav />
      <Outlet />
      <Footer />
    </>
  );
};

export default Layout;
