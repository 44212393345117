import { StyleSheet } from 'aphrodite';
import { theme } from '../../styles/theme';
import { ButtonVariant } from './button';

export const buttonStyles = StyleSheet.create({
  base: {
    borderRadius: theme.size.xlarge,
    transition: 'all 0.2s ease-in-out',
    width: 'fit-content',
  },
  contentContainerBase: {
    gap: theme.size.xxsmall,
    whiteSpace: 'nowrap',
    alignItems: 'center',
  },
  contentContainerWithoutIcon: {
    padding: `${theme.size.xxxsmall} ${theme.size.medium}`,
  },
  contentContainerWithIcon: {
    padding: `${theme.size.xxxsmall} ${theme.size.small}`,
  },
});

const buttonStylesPrimary = StyleSheet.create({
  button: {
    backgroundColor: theme.colour.purple,
    color: theme.colour.white,
  },
  hover: {
    backgroundColor: theme.colour.white,
    color: theme.colour.purple,
  },
  hoverBorder: {
    outline: `2px ${theme.colour.purple} solid`,
    outlineOffset: '-2px',
  },
  icon: {
    stroke: theme.colour.white,
  },
  iconHover: {
    stroke: theme.colour.purple,
  },
});

const buttonStylesPrimaryBlue = StyleSheet.create({
  button: {
    backgroundColor: theme.colour.blue,
    color: theme.colour.white,
  },
  hover: {
    backgroundColor: theme.colour.white,
    color: theme.colour.blue,
  },
  hoverBorder: {
    outline: `2px ${theme.colour.blue} solid`,
    outlineOffset: '-2px',
  },
  icon: {
    stroke: theme.colour.white,
  },
  iconHover: {
    stroke: theme.colour.blue,
  },
});

const buttonStylesPrimaryGreen = StyleSheet.create({
  button: {
    backgroundColor: theme.colour.green,
    color: theme.colour.white,
  },
  hover: {
    backgroundColor: theme.colour.white,
    color: theme.colour.green,
  },
  hoverBorder: {
    outline: `2px ${theme.colour.green} solid`,
    outlineOffset: '-2px',
  },
  icon: {
    stroke: theme.colour.white,
  },
  iconHover: {
    stroke: theme.colour.green,
  },
});

const buttonStylesSecondary = StyleSheet.create({
  button: {
    backgroundColor: theme.colour.white,
    color: theme.colour.purple,
  },
  hover: {
    backgroundColor: theme.colour.purple,
    color: theme.colour.white,
  },
  hoverBorder: {
    outline: `2px ${theme.colour.white} solid`,
    outlineOffset: '-2px',
  },
  icon: {
    stroke: theme.colour.purple,
  },
  iconHover: {
    stroke: theme.colour.white,
  },
});

const buttonStylesSecondaryBlack = StyleSheet.create({
  button: {
    backgroundColor: theme.colour.white,
    color: theme.colour.black,
  },
  hover: {
    backgroundColor: theme.colour.purple,
    color: theme.colour.white,
  },
  hoverBorder: {
    outline: `2px ${theme.colour.white} solid`,
    outlineOffset: '-2px',
  },
  icon: {
    stroke: theme.colour.black,
  },
  iconHover: {
    stroke: theme.colour.white,
  },
});

const buttonStylesSecondaryBlue = StyleSheet.create({
  button: {
    backgroundColor: theme.colour.white,
    color: theme.colour.blue,
  },
  hover: {
    backgroundColor: theme.colour.blue,
    color: theme.colour.white,
  },
  hoverBorder: {
    outline: `2px ${theme.colour.white} solid`,
    outlineOffset: '-2px',
  },
  icon: {
    stroke: theme.colour.blue,
  },
  iconHover: {
    stroke: theme.colour.white,
  },
});

export const getButtonThemeStyles = (variant: ButtonVariant) => {
  switch (variant) {
    case 'primary-blue': {
      return buttonStylesPrimaryBlue;
    }
    case 'primary-green': {
      return buttonStylesPrimaryGreen;
    }
    case 'secondary': {
      return buttonStylesSecondary;
    }
    case 'secondary-black': {
      return buttonStylesSecondaryBlack;
    }
    case 'secondary-blue': {
      return buttonStylesSecondaryBlue;
    }
    default: {
      return buttonStylesPrimary;
    }
  }
};
