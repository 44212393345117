import React from 'react';
import { theme } from '../../styles/theme';
import { css } from 'aphrodite';
import { commonStyles } from '../../styles/common.styles';
import cardStyles from './card.styles';

export type CardProps = {
  children: React.ReactNode;
  id?: string;
  customClass?: object;
  backgroundColor?: string;
  padding?: string;
  fullWidth?: boolean;
  fullHeight?: boolean;
  onClick?: () => void;
  clickable?: boolean;
  focusable?: boolean;
};

const Card = ({
  children,
  id,
  customClass,
  backgroundColor = theme.colour.white,
  padding,
  fullWidth = false,
  fullHeight = false,
  onClick,
  clickable,
  focusable,
}: CardProps) => {
  return clickable || onClick ? (
    <button
      id={id}
      className={css(
        cardStyles.nativeReset,
        commonStyles.clickable,
        commonStyles.shadow,
        cardStyles.card,
        !fullWidth && cardStyles.fitContent,
        onClick && commonStyles.clickable,
        commonStyles.smooth,
        customClass
      )}
      style={{
        backgroundColor,
        padding: padding ?? theme.size.small,
        height: fullHeight
          ? `calc(100% - calc(2 * ${padding ?? theme.size.small}))`
          : undefined,
      }}
      onClick={onClick}
      tabIndex={focusable ? 0 : -1}
    >
      {children}
    </button>
  ) : (
    <div
      id={id}
      className={css(
        commonStyles.shadow,
        cardStyles.card,
        !fullWidth && cardStyles.fitContent,
        onClick && commonStyles.clickable,
        commonStyles.smooth,
        customClass
      )}
      style={{
        backgroundColor,
        padding: padding ?? theme.size.small,
        height: fullHeight
          ? `calc(100% - calc(2 * ${padding ?? theme.size.small}))`
          : undefined,
      }}
      onClick={onClick}
      tabIndex={focusable ? 0 : -1}
    >
      {children}
    </div>
  );
};

export default Card;
