import React from 'react';
import { Section } from '../../../../../types/general';
import { css } from 'aphrodite';
import { commonStyles } from '../../../../../styles/common.styles';

export type SectionDetailsPromiseDataItem = {
  variant: string;
  promise: string | string[];
};

export type SectionDetailsPromiseData = SectionDetailsPromiseDataItem[];

export type SectionDetailsPromiseProps = {
  section: Section;
  data: SectionDetailsPromiseData;
  introduction: string;
};

const SectionDetailsPromise = ({
  section,
  data,
  introduction
}: SectionDetailsPromiseProps) => {
  return (
    <>
      <p className={css(commonStyles.noMarginTop)}>
        {introduction}
      </p>
      {data.map((dataItem, index) => (
        <p
          key={`promise-${index}`}
          className={
            index === data.length - 1
              ? css(commonStyles.noMarginBottom)
              : undefined
          }
        >
          <b>
            The {section.slice(0, -1)} Scout Promise for members who are {dataItem.variant}
          </b>
          <br />
          {Array.isArray(dataItem.promise)
            ? dataItem.promise.map((line, index) => (
                <div key={`promise-line-${index}`}>{line}</div>
              ))
            : dataItem.promise}
        </p>
      ))}
    </>
  );
};

export default SectionDetailsPromise;
