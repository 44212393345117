import { StyleSheet } from 'aphrodite';
import { theme } from '../../../styles/theme';

const menuDropdownStyles = StyleSheet.create({
  container: {
    backgroundColor: theme.colour.offWhite,
  },
  containerAtTop: {
    borderRadius: theme.size.small,

    padding: `${theme.size.medium} 0`,
  },
  containerScrolled: {
    borderRadius: `0 0 ${theme.size.small} ${theme.size.small}`,
    paddingBottom: theme.size.medium,
    paddingTop: theme.size.xxsmall,
    clipPath: 'inset(0px -100px -100px -100px)',
  },
  parentContainer: {
    position: 'absolute',
    left: '-22px',
  },
  itemContainer: {
    padding: `0 ${theme.size.medium}`,
    transition: 'color 0.2s ease-in-out',
  },
  inactive: {
    color: theme.colour.grey[40],
  },
  hidden: {
    opacity: 0,
    visibility: 'hidden',
  },
  containerHidden: {
    visibility: 'hidden',
  },
  visible: {
    opacity: 1,
    visibility: 'visible',
  },
  fade: {
    transition: 'opacity 0.2s ease-in-out, visibility 0.2s ease',
  },
  containerFade: {
    transition: 'visibility 0.2s ease',
  },
});

export default menuDropdownStyles;
