import { StyleSheet } from 'aphrodite';
import { theme } from '../../../styles/theme';

const homeSectionsStyles = StyleSheet.create({
  sectionContainer: {
    margin: `0 4vw 4vw 4vw`,
  },
  doodleContainer: {
    width: '15%',
    marginRight: '6vw',
  },
  doodleContainerSmall: {
    width: '20%',
  },
  doodleContainerLarge: {
    width: '15%',
  },
  heading: {
    margin: `0 0 ${theme.size.large} 0`,
    width: 'min-content',
    textWrap: 'nowrap',
  },
  cardsContainer: {
    maxWidth: '1440px',
  },
});

export default homeSectionsStyles;
