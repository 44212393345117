const basePath = '/media';

const mediaPathStems = {
  brand: `${basePath}/brand`,
  images: `${basePath}/images`,
  illustrations: `${basePath}/illustrations`,
  video: `${basePath}/video`,
  people: `${basePath}/images/people`,
  uniform: `${basePath}/images/uniform`
};

export const media = {
  brand: {
    group: {
      linear: {
        colour: { src: `${mediaPathStems.brand}/2ndRugeley_Linear_Colour.svg`, alt: '2nd Rugeley Scout Group Logo' },
        white: { src: `${mediaPathStems.brand}/2ndRugeley_Linear_White.svg`, alt: '2nd Rugeley Scout Group Logo' },
      },
      stacked: {
        white: { src: `${mediaPathStems.brand}/2ndRugeley_Stacked_White.svg`, alt: '2nd Rugeley Scout Group Logo' },
      },
    },
    global: {
      fleurDeLis: {
        white: {src: `${mediaPathStems.brand}/FleurDeLis_White.svg`, alt: 'Scouts Fleur-de-lis'},
      },
    },
    sections: {
      squirrels: {
        white: { src: `${mediaPathStems.brand}/Squirrels_Primary_White.svg`, alt: 'Squirrels Logo' },
      },
      beavers: {
        white: { src: `${mediaPathStems.brand}/Beavers_Primary_White.svg`, alt: 'Beavers Logo' },
      },
      cubs: {
        white: { src: `${mediaPathStems.brand}/Cubs_Primary_White.svg`, alt: 'Cubs Logo' },
      },
      scouts: {
        white: { src: `${mediaPathStems.brand}/Scouts_Primary_White.svg`, alt: 'Scouts Logo' },
      },
      explorers: {
        white: { src: `${mediaPathStems.brand}/Explorers_Primary_White.svg`, alt: 'Explorers Logo' }
      },
      network: {
        white: { src: `${mediaPathStems.brand}/Network_Primary_White.svg`, alt: 'Network Logo' },
      },
    },
  },
  images: {
    background: {
      campfire: { src: `${mediaPathStems.images}/SCO_campfire.jpg`, alt: 'Burning campfire with trees in the background' },
      pattern: { src: `${mediaPathStems.illustrations}/SCO_PatternBackground.svg`, alt: 'Patterned background'},
    },
    index: {
      static: [
        { src: `${mediaPathStems.images}/SCO_index_static_1.jpg`, alt: 'A Scout swinging on a tire rope swing' },
        { src: `${mediaPathStems.images}/SCO_index_static_2.jpg`, alt: 'Three Scouts posing at the top of a zipwire' },
        { src: `${mediaPathStems.images}/SCO_index_static_3.jpg`, alt: 'A Scout looking at the view of Swiss hills from a boat' },
        { src: `${mediaPathStems.images}/SCO_index_static_4.jpg`, alt: 'Three Beaver Scouts working together on an activity' },
        { src: `${mediaPathStems.images}/SCO_index_static_5.jpg`, alt: 'A Cub Scout riding a bike' },
        { src: `${mediaPathStems.images}/SCO_index_static_6.jpg`, alt: 'A Squirrel Scout showing off their plant creation' },
      ],
      introduction: { src: `${mediaPathStems.illustrations}/SCO_index_introduction.png`, alt: 'A collage of 4 images, one of a Squirrel Scout showing off their creation, another of a Beaver Scout dancing, another of 2 Scouts parading, and another of an Explorer Scout on a zipwire' },
      startYourJourney: { src: `${mediaPathStems.illustrations}/SCO_StartYourJourney.png`, alt: 'A doodle illustration of a paper aeroplane being launched upwards' },
      underline: { src: `${mediaPathStems.illustrations}/SCO_underline.svg`, alt: 'An underline'},
      volunteer: { src: `${mediaPathStems.illustrations}/SCO_index_volunteer.png`, alt: 'Scout Leader leading an activity with a group of Scouts on camp' },
      sheepfair: { src: `${mediaPathStems.illustrations}/SCO_index_sheepfair.png`, alt: 'The Main Hall at Sheepfair Community Centre' }
    },
    contact: {
      introduction: { src: `${mediaPathStems.illustrations}/SCO_contact_introduction.svg`, alt: 'Illustration of a speech bubble with a dotted line trailing off' },
    },
    people: {
      placeholder: { src: `${mediaPathStems.people}/SCO_NeckerPlaceholder.svg`, alt: 'Placeholder image of a Scout Leader' },
      hopeNolan: { src: `${mediaPathStems.people}/SCO_Hope_Nolan.jpg`, alt: 'Hope Nolan' },
      martinMillson: { src: `${mediaPathStems.people}/SCO_Martin_Millson.jpg`, alt: 'Martin Millson' },
      archieBillingsley: { src: `${mediaPathStems.people}/SCO_Archie_Billingsley.jpg`, alt: 'Archie Billingsley' },
      joanneCarter: { src: `${mediaPathStems.people}/SCO_Joanne_Carter.jpg`, alt: 'Joanne Carter' },
      benjaminGriffiths: { src: `${mediaPathStems.people}/SCO_Benjamin_Griffiths.jpg`, alt: 'Benjamin Griffiths' },
    },
    sections: {
      squirrels: {
        header: [
          { src: `${mediaPathStems.images}/SCO_squirrels_header_1.jpg`, alt: 'Three Squirrel Scouts sat on a Bench, smiling and laughing', pos: '50% 30%' },
          { src: `${mediaPathStems.images}/SCO_squirrels_header_2.jpg`, alt: 'Two Squirrel Scouts running and laughing', pos: '50% 70%' }
        ],
        introduction: { src: `${mediaPathStems.illustrations}/SCO_squirrels_introduction.png`, alt: 'A collage of 2 images, one with a Squirrel Scout showing off their creation, and another of a Squirrel Scout running in a field' },
        uniform: { src: `${mediaPathStems.uniform}/squirrels-uniform-badge-placement.png`, alt: 'A diagram showing the placement of badges on the Squirrel uniform'},
      },
      beavers: {
        header: [
          { src: `${mediaPathStems.images}/SCO_beavers_header_1.jpg`, alt: 'Two Beaver Scouts outside sitting and talking', pos: '50% 50%'},
          { src: `${mediaPathStems.images}/SCO_beavers_header_2.jpg`, alt: 'A Beaver Scout dancing and smiling', pos: '50% 20%'}
        ],
        introduction: { src: `${mediaPathStems.illustrations}/SCO_beavers_introduction.png`, alt: 'A collage of 2 images, one with 3 Beaver Scouts working together to solve a puzzle, and another of Beaver Scouts showing off their creations' },
        uniform: { src: `${mediaPathStems.uniform}/beavers-uniform-badge-placement.png`, alt: 'A diagram showing the placement of badges on the Beaver uniform'},
      }
    }
  },
  video: {
    index: {src: `${mediaPathStems.video}/SCO_index_background_video.mp4`, alt: 'Background video showing a campfire, and other various Scouting activities'}
  }
};
