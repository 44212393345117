import { css } from 'aphrodite';
import React from 'react';
import { commonStyles } from '../../../../styles/common.styles';
import { theme } from '../../../../styles/theme';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebookMessenger } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ContactData } from '../../../../types/general';
import { Link } from 'react-router-dom';
import contactCardStyles from './contact-card-content.styles';

export type ContactCardContentProps = {
  contactData: ContactData;
  size?: 'small' | 'large';
  color?: string;
};

const ContactCardContent = ({ contactData, size = 'small', color = theme.colour.white }: ContactCardContentProps) => {
  const {
    name,
    title = 'Section Leader',
    img,
    email,
    tel,
    messenger,
  } = contactData;

  const firstName = React.useMemo(() => name.split(' ')[0], [name]);

  return (
    <div
      className={css(
        commonStyles.flex,
        commonStyles.fullWidth,
        commonStyles.gapSmall,
      )}
    >
      <div style={{ width: '40%' }}>
        <img
          src={img?.src}
          width={'100%'}
          style={{ borderRadius: theme.size.small }}
          alt={img?.alt}
        />
      </div>
      <div
        className={css(
          commonStyles.flex,
          commonStyles.flexColumn,
          commonStyles.textAlignRight,
          commonStyles.flexGrow,
        )}
        style={{ width: '60%', color }}
      >
        <div>
          <div
            className={css(
              commonStyles.noWrapText,
              commonStyles.boldestText,
              size === 'small'
                ? contactCardStyles.nameSmall
                : contactCardStyles.nameLarge,
            )}
          >
            {name}
          </div>
          <div
            className={css(
              size === 'small'
                ? contactCardStyles.titleSmall
                : contactCardStyles.titleLarge,
            )}
          >
            {title}
          </div>
        </div>
        <div
          className={css(
            commonStyles.flex,
            commonStyles.centreVertical,
            commonStyles.flexGrow,
          )}
        >
          <div
            className={css(
              commonStyles.flex,
              commonStyles.fullWidth,
              commonStyles.centreHorizontal,
              commonStyles.spaceEvenly,
              size === 'small'
                ? contactCardStyles.linksContainerSmall
                : contactCardStyles.linksContainerLarge,
            )}
          >
            <Link
              to={`mailto:${email}`}
              title={`Email ${firstName}`}
              className={css(
                commonStyles.nativeReset,
                commonStyles.flex,
                commonStyles.opacityHover,
              )}
            >
              <FontAwesomeIcon icon={faEnvelope} />
            </Link>
            {tel && (
              <Link
                to={`tel:${tel}`}
                title={`Phone ${firstName}`}
                className={css(
                  commonStyles.nativeReset,
                  commonStyles.flex,
                  commonStyles.opacityHover,
                )}
              >
                <FontAwesomeIcon icon={faPhone} />
              </Link>
            )}
            {messenger && (
              <Link
                to={'http://m.me/2ndrugeley'}
                title={`Chat to ${firstName} on Messenger`}
                className={css(
                  commonStyles.nativeReset,
                  commonStyles.flex,
                  commonStyles.opacityHover,
                )}
                target='_blank'
              >
                <FontAwesomeIcon icon={faFacebookMessenger} />
              </Link>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ContactCardContent;
