import React from 'react';
import { Section } from '../../../../../types/general';
import { commonStyles } from '../../../../../styles/common.styles';
import { css } from 'aphrodite';
import Button from '../../../../../components/button/button';
import { faDownload } from '@fortawesome/free-solid-svg-icons';
import { media } from '../../../../../util/media';
import { Link } from 'react-router-dom';
import { useScreenSizeContext } from '../../../../../util/screensize.context';

export type SectionDetailsBadgePlacementProps = {
  section: Section;
  isVisible?: boolean;
};

const SectionDetailsBadgePlacement = ({
  section,
  isVisible,
}: SectionDetailsBadgePlacementProps) => {
  const { isScreenSmallOrLess } = useScreenSizeContext();

  const img = React.useMemo(
    () =>
      media.images.sections[
        section.toLowerCase() as keyof typeof media.images.sections
      ].uniform,
    [section],
  );

  return (
    <>
      <p className={css(commonStyles.noMarginTop)}>
        Please see the diagram below to see where the badges your {section.slice(0, -1)}{' '}
        earns are placed on their uniform.
      </p>
      <div className={css(commonStyles.flex, commonStyles.fullWidth)}>
        <Button
          label='Download image'
          borderOnHover
          icon={faDownload}
          link={img.src}
          linkInNewTab
          focusable={isVisible}
        />
      </div>
      <div
        className={css(
          commonStyles.flex,
          commonStyles.centreHorizontal,
          commonStyles.marginTopSmall,
        )}
      >
        <Link
          to={img.src}
          target='_blank'
          style={{ width: isScreenSmallOrLess ? '100%' : '60%' }}
          tabIndex={isVisible ? 0 : -1}
        >
          <img src={img.src} alt={img.alt} width={'100%'} />
        </Link>
      </div>
    </>
  );
};

export default SectionDetailsBadgePlacement;
