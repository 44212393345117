import { css } from 'aphrodite';
import React from 'react';
import baseSectionCardStyles from './base-section-card.styles';
import { commonStyles } from '../../../styles/common.styles';
import { media } from '../../../util/media';
import { MediaItem, SectionStyle } from '../../../types/general';
import { theme } from '../../../styles/theme';
import { useScreenSizeContext } from '../../../util/screensize.context';
import { Link } from 'react-router-dom';

export type BaseSectionCardProps = {
  variant: SectionStyle;
  children: React.ReactNode;
  width?: string;
  height?: string;
  onHover?: () => void;
  onUnhover?: () => void;
  link?: string;
  customPadding?: string;
  centre?: boolean | 'horizontally' | 'vertically';
  id?: string;
};

type BaseSectionCardData = {
  backgroundColour: string;
  logo?: MediaItem;
};

const sectionData = new Map<SectionStyle, BaseSectionCardData>([
  [
    'Squirrels',
    {
      backgroundColour: theme.colour.red,
      logo: media.brand.sections.squirrels.white,
    },
  ],
  [
    'Beavers',
    {
      backgroundColour: theme.colour.blue,
      logo: media.brand.sections.beavers.white,
    },
  ],
  [
    'Cubs',
    {
      backgroundColour: theme.colour.green,
      logo: media.brand.sections.cubs.white,
    },
  ],
  [
    'Scouts',
    {
      backgroundColour: theme.colour.teal,
      logo: media.brand.sections.scouts.white,
    },
  ],
  [
    'Explorers',
    {
      backgroundColour: theme.colour.navy,
      logo: media.brand.sections.explorers.white,
    },
  ],
  [
    'Network',
    {
      backgroundColour: theme.colour.black,
      logo: media.brand.sections.network.white,
    },
  ],
  [
    'General',
    {
      backgroundColour: theme.colour.purple,
    },
  ],
]);

const BaseSectionCard = ({
  variant,
  children,
  width,
  height,
  onHover,
  onUnhover,
  link,
  customPadding,
  centre,
  id,
}: BaseSectionCardProps) => {
  return link ? (
    <Link to={link} className={css(commonStyles.nativeReset)} tabIndex={-1}>
      <BaseSectionCardContent
        variant={variant}
        width={width}
        height={height}
        onHover={onHover}
        onUnhover={onUnhover}
        customPadding={customPadding}
        centre={centre}
        id={id}
      >
        {children}
      </BaseSectionCardContent>
    </Link>
  ) : (
    <BaseSectionCardContent
      variant={variant}
      width={width}
      height={height}
      onHover={onHover}
      onUnhover={onUnhover}
      customPadding={customPadding}
      centre={centre}
      id={id}
    >
      {children}
    </BaseSectionCardContent>
  );
};

const BaseSectionCardContent = ({
  variant,
  children,
  width,
  height,
  onHover,
  onUnhover,
  link,
  customPadding,
  centre,
  id,
}: BaseSectionCardProps) => {
  const data = React.useMemo(() => sectionData.get(variant), [variant]);

  const { isScreenSmallOrLess } = useScreenSizeContext();

  return (
    <div
      className={css(
        !!link && commonStyles.clickable,
        baseSectionCardStyles.container,
        commonStyles.shadow,
      )}
      style={{
        backgroundColor: data?.backgroundColour,
        width,
        height,
        padding: customPadding ?? (isScreenSmallOrLess ? '4vw' : '1.5vw'),
      }}
      onMouseOver={() => {
        if (onHover) {
          onHover();
        }
      }}
      onMouseLeave={() => {
        if (onUnhover) {
          onUnhover();
        }
      }}
      onFocus={() => {
        if (onHover) {
          onHover();
        }
      }}
      onBlur={() => {
        if (onUnhover) {
          onUnhover();
        }
      }}
      tabIndex={0}
      id={id}
    >
      <div
        className={css(
          commonStyles.flex,
          commonStyles.flexColumn,
          commonStyles.fullHeight,
          (centre === true || centre === 'vertically') &&
            commonStyles.centreHorizontal,
          (centre === true || centre === 'horizontally') &&
            commonStyles.centreVertical,
        )}
      >
        {children}
        {data?.logo && (
          <div
            className={css(
              commonStyles.flex,
              commonStyles.justifyEnd,
              commonStyles.alignEnd,
              commonStyles.fullHeight,
              commonStyles.fullWidth,
            )}
          >
            <div
              className={css(
                baseSectionCardStyles.logoContainer,
                isScreenSmallOrLess
                  ? baseSectionCardStyles.logoContainerSmall
                  : baseSectionCardStyles.logoContainerLarge,
              )}
            >
              <img
                src={data.logo.src}
                className={css(baseSectionCardStyles.logoImg)}
                width={'100%'}
                height={'100%'}
                alt={data.logo.alt}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default BaseSectionCard;
