export enum Page {
  Home = '/',
  Squirrels = '/squirrels',
  Beavers = '/beavers',
  Cubs = '/cubs',
  Scouts = '/scouts',
  Explorers = '/explorers',
  Network = '/network',
  Volunteer = '/volunteer',
  Contact = '/contact',
  Join = '/join',
}
