import React from 'react';
import { theme } from '../../../styles/theme';
import { useScreenSizeContext } from '../../../util/screensize.context';
import UnderlineLink from '../../underline-link/underline-link';
import { css } from 'aphrodite';
import { commonStyles } from '../../../styles/common.styles';
import footerStyles from './footer.styles';
import Button from '../../button/button';
import SocialIconBar from './social-icon/social-icon-bar';
import { useLocation } from 'react-router-dom';
import { media } from '../../../util/media';
import { Page } from '../../../types/navigation';
import applicationVariables from '../../../util/variables';

const determineScreenIsSmallish = (screenWidth: number) =>
  screenWidth < theme.breakpoints.medium && screenWidth >= 660;

const determineScreenIsExtraLarge = (screenWidth: number) =>
  screenWidth >= 1440;

const pagesWithVectorForAttribution: string[] = [Page.Contact];

const determineShowVectorAttribution = (location: string) => {
  return pagesWithVectorForAttribution.includes(location);
};

const FooterCopyrightDetails = () => {
  const location = useLocation();

  const showVectorAttribution = React.useMemo(
    () => determineShowVectorAttribution(location.pathname),
    [location],
  );
  return (
    <>
      <div
        className={css(
          commonStyles.textWhite,
          footerStyles.text,
          commonStyles.marginTopLarge,
        )}
      >
        © 2nd Rugeley Scout Group {new Date().getUTCFullYear()}. All Rights
        Reserved.
      </div>
      <div
        className={css(
          commonStyles.textWhite,
          commonStyles.marginTopSmall,
          footerStyles.appendumText,
        )}
      >
        Website designed, built and maintained by Benjamin Griffiths for and on
        behalf of 2nd Rugeley Scout Group
      </div>
      {showVectorAttribution && (
        <div
          className={css(
            commonStyles.textWhite,
            commonStyles.marginTopXXXSmall,
            footerStyles.appendumText,
          )}
        >
          Selected vectors on this page attributable to&nbsp;
          <UnderlineLink
            text='Vecteezy.com'
            inline
            link='https://www.vecteezy.com/'
            linkInNewTab
            textColour={theme.colour.white}
          />
        </div>
      )}

      <div
        className={css(
          commonStyles.textWhite,
          showVectorAttribution
            ? commonStyles.marginTopSmall
            : commonStyles.marginTopXXXSmall,
          footerStyles.appendumText,
        )}
      >
        <div
          className={css(
            commonStyles.flex,
            footerStyles.appendumDividerLine,
            commonStyles.noWrapText,
          )}
        >
          <span>Last Published: {applicationVariables.publishedDate}</span>
          <span>|</span>
          <span>Version {applicationVariables.publishedVersion}</span>
        </div>
      </div>
    </>
  );
};

type FooterColumnProps = {
  width?: string;
};

type ColumnOneProps = FooterColumnProps & {
  direction?: 'row' | 'column';
};

type ColumnThreeProps = FooterColumnProps & {
  alignment?: 'left' | 'right';
};

const ColumnOne = ({ width, direction = 'column' }: ColumnOneProps) => {
  const { screenWidth, isScreenSmallOrLess } = useScreenSizeContext();

  const isScreenExtraLarge = React.useMemo(
    () => determineScreenIsExtraLarge(screenWidth),
    [screenWidth],
  );

  return (
    <div
      className={css(
        commonStyles.flex,
        commonStyles.spaceBetween,
        footerStyles.columnOneContainer,
      )}
      style={{
        width,
        flexDirection: direction,
      }}
    >
      <div
        className={css(
          isScreenExtraLarge
            ? footerStyles.logoContainerExtraLarge
            : isScreenSmallOrLess
              ? footerStyles.logoContainer
              : footerStyles.logoContainerMedium,
        )}
      >
        <img
          src={media.brand.group.stacked.white.src}
          width={'100%'}
          alt={media.brand.group.stacked.white.alt}
        />
      </div>
      <div>
        <div
          className={css(
            commonStyles.textWhite,
            footerStyles.text,
            commonStyles.noWrapText,
            commonStyles.marginTopLarge,
            direction === 'row' && commonStyles.flex,
            direction === 'row' && commonStyles.justifyEnd,
          )}
        >
          <div className={css(commonStyles.flex, footerStyles.dividerLine)}>
            <span>Registered Charity No. 1111375</span>
            <span>|</span>
            <UnderlineLink
              text='Privacy Policy'
              link={'/files/logos/2ndRugeley_PrivacyPolicy_July2022.pdf'}
              inline
              textColour={theme.colour.white}
            />
          </div>
        </div>
        <div
          className={css(
            commonStyles.textWhite,
            footerStyles.text,
            commonStyles.marginTopXXXSmall,
            direction === 'row' && commonStyles.flex,
            direction === 'row' && commonStyles.justifyEnd,
          )}
        >
          <div className={css(commonStyles.flex, footerStyles.dividerLine)}>
            <UnderlineLink
              text='Data Retention Policy'
              link={'/files/2ndRugeley_PrivacyPolicy_July2022.pdf'}
              inline
              textColour={theme.colour.white}
            />
            <span>|</span>
            <UnderlineLink
              text='Manage Cookie Preferences'
              link={'/files/2ndRugeley_PrivacyPolicy_July2022.pdf'}
              inline
              textColour={theme.colour.white}
            />
          </div>
        </div>
      </div>
      {!isScreenSmallOrLess && <FooterCopyrightDetails />}
    </div>
  );
};

const ColumnTwo = ({ width }: FooterColumnProps) => {
  return (
    <div
      className={css(footerStyles.columnTwoContainer)}
      style={{
        width,
      }}
    >
      <div
        className={css(
          commonStyles.flex,
          commonStyles.flexColumn,
          footerStyles.text,
        )}
      >
        <h3 className={css(commonStyles.textWhite, footerStyles.heading)}>
          Get Involved
        </h3>
        <UnderlineLink
          text='Squirrels'
          link='/squirrels'
          textColour={theme.colour.white}
        />
        <UnderlineLink
          text='Beavers'
          link='/beavers'
          textColour={theme.colour.white}
        />
        <UnderlineLink
          text='Cubs'
          link='/cubs'
          textColour={theme.colour.white}
        />
        <UnderlineLink
          text='Scouts'
          link='/scouts'
          textColour={theme.colour.white}
        />
        <UnderlineLink
          text='Explorers'
          link='/explorers'
          textColour={theme.colour.white}
        />
        <UnderlineLink
          text='Network'
          link='/network'
          textColour={theme.colour.white}
        />
        <UnderlineLink
          text='Adult Volunteers'
          link='/volunteer'
          textColour={theme.colour.white}
        />
        <h3
          className={css(
            commonStyles.textWhite,
            footerStyles.heading,
            commonStyles.marginTopSmall,
          )}
        >
          Hire
        </h3>
        <UnderlineLink
          text='Sheepfair Community Centre'
          link={applicationVariables.sheepfairUrl}
          textColour={theme.colour.white}
          linkInNewTab
        />
      </div>
    </div>
  );
};

const ColumnThree = ({ width, alignment = 'left' }: ColumnThreeProps) => {
  return (
    <div className={css(footerStyles.columnThreeContainer)} style={{ width }}>
      <div
        className={css(
          commonStyles.flex,
          commonStyles.flexColumn,
          footerStyles.text,
          alignment === 'right' && commonStyles.textAlignRight,
        )}
      >
        <h3
          className={css(
            commonStyles.textWhite,
            footerStyles.heading,
            commonStyles.noMargin,
          )}
        >
          Get In Touch
        </h3>
        <div
          className={css(
            commonStyles.textWhite,
            commonStyles.marginBottomXSmall,
          )}
        >
          For all enquiries, please see our contact page
        </div>
        <div>
          <Button
            variant='secondary'
            label='Contact Us'
            bold
            fontSize='16px'
            borderOnHover
            link={Page.Contact}
          />
        </div>
        <h4
          className={css(
            commonStyles.textWhite,
            footerStyles.subHeading,
            commonStyles.marginBottomXXXSmall,
          )}
        >
          Find Us
        </h4>
        <div className={css(commonStyles.textWhite)}>
          <div>Sheepfair Community Centre</div>
          <div>Sheep Fair</div>
          <div>Rugeley</div>
          <div>WS15 2AT</div>
        </div>
        <h4 className={css(commonStyles.textWhite, footerStyles.subHeading)}>
          Follow Us
        </h4>
        <div
          className={css(
            commonStyles.flex,
            alignment === 'right' && commonStyles.justifyEnd,
          )}
        >
          <SocialIconBar />
        </div>
      </div>
    </div>
  );
};

const Footer = () => {
  const { screenWidth, isScreenMediumOrLess, isScreenSmallOrLess } =
    useScreenSizeContext();

  const isScreenSmallish = React.useMemo(
    () => determineScreenIsSmallish(screenWidth),
    [screenWidth],
  );

  const isScreenExtraLarge = React.useMemo(
    () => determineScreenIsExtraLarge(screenWidth),
    [screenWidth],
  );

  return (
    <div className={css(commonStyles.fullWidth, footerStyles.background)}>
      <div
        className={css(
          isScreenExtraLarge
            ? footerStyles.containerExtraLarge
            : isScreenMediumOrLess
              ? footerStyles.containerMedium
              : footerStyles.container,
        )}
      >
        {isScreenSmallish ? (
          <div
            className={css(
              commonStyles.flex,
              commonStyles.flexColumn,
              commonStyles.gapLarge,
            )}
          >
            <div>
              <ColumnOne direction='row' />
            </div>
            <div
              className={css(commonStyles.flex, commonStyles.centreVertical)}
            >
              <ColumnTwo width='50%' />
              <ColumnThree width='50%' alignment='right' />
            </div>
            <div>
              <FooterCopyrightDetails />
            </div>
          </div>
        ) : (
          <div
            className={css(
              commonStyles.flex,
              isScreenSmallOrLess && commonStyles.flexColumn,
              isScreenSmallOrLess && commonStyles.gapMedium,
              commonStyles.spaceBetween,
            )}
          >
            <ColumnOne width='45%' />
            <ColumnTwo width='30%' />
            <ColumnThree />

            {isScreenSmallOrLess && (
              <div>
                <FooterCopyrightDetails />
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Footer;
