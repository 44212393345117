import { css } from 'aphrodite';
import React from 'react';
import { layoutStyles } from '../layout.styles';
import { Page } from '../../../types/navigation';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { commonStyles } from '../../../styles/common.styles';
import { useScreenSizeContext } from '../../../util/screensize.context';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faBars } from '@fortawesome/free-solid-svg-icons';
import Button from '../../button/button';
import { useSessionContext } from '../../../util/session.context';
import { theme } from '../../../styles/theme';
import MenuDropdown from '../menu-dropdown/menu-dropdown';
import { media } from '../../../util/media';

type DesktopNavItemProps = {
  index: number;
  label: string;
  link?: string;
  hoveredItem?: number;
  isHoveredOverride?: boolean;
  setHoveredItem: (index?: number) => void;
  isNavButtonHover: boolean;
  onHover?: () => void;
  onUnhover?: () => void;
  dropdown?: boolean;
};

const DesktopNavItem = ({
  index,
  label,
  link,
  hoveredItem,
  isHoveredOverride,
  setHoveredItem,
  isNavButtonHover,
  onHover,
  onUnhover,
  dropdown,
}: DesktopNavItemProps) => {
  return link ? (
    <Link to={link} className={css(commonStyles.nativeReset)} tabIndex={-1}>
      <DesktopNavItemContent
        index={index}
        label={label}
        hoveredItem={hoveredItem}
        isHoveredOverride={isHoveredOverride}
        setHoveredItem={setHoveredItem}
        isNavButtonHover={isNavButtonHover}
        onHover={onHover}
        onUnhover={onUnhover}
        dropdown={dropdown}
      />
    </Link>
  ) : (
    <DesktopNavItemContent
      index={index}
      label={label}
      hoveredItem={hoveredItem}
      isHoveredOverride={isHoveredOverride}
      setHoveredItem={setHoveredItem}
      isNavButtonHover={isNavButtonHover}
      onHover={onHover}
      onUnhover={onUnhover}
      dropdown={dropdown}
    />
  );
};

const DesktopNavItemContent = ({
  index,
  label,
  hoveredItem,
  isHoveredOverride,
  setHoveredItem,
  isNavButtonHover,
  onHover,
  onUnhover,
  dropdown,
}: DesktopNavItemProps) => {
  const { isScreenScrolled } = useScreenSizeContext();
  return (
    <button
      className={css(
        commonStyles.flex,
        commonStyles.gapXXSmall,
        commonStyles.clickable,
        layoutStyles.navItem,
        commonStyles.nativeReset,
        isHoveredOverride
          ? isScreenScrolled
            ? layoutStyles.navItemEnabledScrolled
            : layoutStyles.navItemEnabled
          : (hoveredItem !== undefined || isNavButtonHover) &&
              !(isScreenScrolled && hoveredItem === 3) &&
              hoveredItem !== index
            ? layoutStyles.navItemDisabled
            : isScreenScrolled
              ? layoutStyles.navItemEnabledScrolled
              : layoutStyles.navItemEnabled,
      )}
      onMouseOver={() => {
        setHoveredItem(index);
        if (onHover) {
          onHover();
        }
      }}
      onFocus={() => {
        setHoveredItem(index);
        if (onHover) {
          onHover();
        }
      }}
      onMouseLeave={() => {
        setHoveredItem(undefined);
        if (onUnhover) {
          onUnhover();
        }
      }}
    >
      <div className={css(commonStyles.bold)}>{label}</div>
      {dropdown && <FontAwesomeIcon icon={faAngleDown} />}
    </button>
  );
};

const DesktopNav = () => {
  const [hoveredItem, setHoveredItem] = React.useState<number | undefined>(
    undefined,
  );
  const [isNavButtonHover, setIsNavButtonHover] = React.useState(false);
  const [sectionHoverOverride, setSectionHoverOverride] = React.useState(false);


  const { isScreenLargeOrMore, isScreenScrolled } = useScreenSizeContext();
  const { openMobileMenu, openSectionDropdown, closeSectionDropdown } = useSessionContext();

  const location = useLocation();
  const navigate = useNavigate();

  const logoImage = React.useMemo(
    () => media.brand.group.linear[isScreenScrolled ? 'colour' : 'white'],
    [isScreenScrolled],
  );

  React.useEffect(() => {
    closeSectionDropdown();
    setIsNavButtonHover(false);
  }, [location]);

  const handleLogoClick = () => {
    if (location.pathname === Page.Home) {
      window.scrollTo({ top: 0, behavior: 'smooth' });
    } else {
      navigate(Page.Home);
    }
  };

  return (
    <div
      className={css(
        layoutStyles.navbar,
        isScreenScrolled && layoutStyles.navbarScrolled,
        isScreenScrolled && commonStyles.shadow,
      )}
    >
      <div
        className={css(
          commonStyles.flex,
          commonStyles.spaceBetween,
          isScreenScrolled && layoutStyles.navItemsContainerScrolled,
        )}
      >
        <button
          className={css(
            commonStyles.clickable,
            commonStyles.nativeReset,
            isScreenScrolled
              ? layoutStyles.logoContainerScrolled
              : layoutStyles.logoContainer,
            layoutStyles.logoTransition,
          )}
          onClick={handleLogoClick}
        >
          <img src={logoImage.src} alt={logoImage.alt} width={'100%'} />
        </button>
        {isScreenLargeOrMore ? (
          <div
            className={css(commonStyles.flex, layoutStyles.navItemContainer)}
            onMouseOver={() => setIsNavButtonHover(true)}
            onMouseLeave={() => setIsNavButtonHover(false)}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignSelf: 'center',
              }}
            >
              <DesktopNavItem
                index={0}
                label='Sections'
                hoveredItem={hoveredItem}
                isHoveredOverride={sectionHoverOverride}
                setHoveredItem={setHoveredItem}
                isNavButtonHover={isNavButtonHover}
                dropdown
                onHover={openSectionDropdown}
                onUnhover={closeSectionDropdown}
              />
              <MenuDropdown
                onDropdownHover={() => setSectionHoverOverride(true)}
                onDropdownUnhover={() => setSectionHoverOverride(false)}
              />
            </div>
            <DesktopNavItem
              index={1}
              label='Volunteer'
              link={Page.Volunteer}
              hoveredItem={hoveredItem}
              setHoveredItem={setHoveredItem}
              isNavButtonHover={isNavButtonHover}
            />
            <DesktopNavItem
              index={2}
              label='Contact Us'
              link={Page.Contact}
              hoveredItem={hoveredItem}
              setHoveredItem={setHoveredItem}
              isNavButtonHover={isNavButtonHover}
            />
            <div className={css(commonStyles.flex, layoutStyles.navItem)}>
              <Button
                label='Join Us'
                bold
                fontSize='20px'
                onHover={() => setHoveredItem(3)}
                onFocus={() => setHoveredItem(3)}
                onUnhover={() => setHoveredItem(undefined)}
                onBlur={() => setHoveredItem(undefined)}
                borderOnHover={isScreenScrolled}
                link={Page.Join}
              />
            </div>
          </div>
        ) : (
          <button
            className={css(commonStyles.nativeReset)}
            onClick={openMobileMenu}
            title='Menu'
          >
            <FontAwesomeIcon
              icon={faBars}
              color={
                isScreenScrolled ? theme.colour.purple : theme.colour.white
              }
              size={'2x'}
            />
          </button>
        )}
      </div>
    </div>
  );
};

export default DesktopNav;
