import { css } from 'aphrodite';
import React from 'react';
import { commonStyles } from '../../../../../styles/common.styles';
import { useScreenSizeContext } from '../../../../../util/screensize.context';
import { CustomIconName } from '../../../../../components/custom-icon/custom-icons';
import Button from '../../../../../components/button/button';
import { Page } from '../../../../../types/navigation';

export type SectionDetailsSafeguardingProps = {
  children: React.ReactNode;
  isVisible?: boolean;
};

const SectionDetailsSafeguarding = ({
  children,
  isVisible,
}: SectionDetailsSafeguardingProps) => {
  const { isScreenSmallOrLess } = useScreenSizeContext();

  return (
    <>
      <p className={css(commonStyles.noMarginTop)}>
        <b>Doing things safely is fundamental to everything we do at Scouts</b>
      </p>
      {children}
      <p>
        If you have any further concerns, now or whilst your child is enrolled
        in any of our sections, please contact the section leader or our Group
        Scout Leader
      </p>
      <div
        className={css(
          commonStyles.flex,
          isScreenSmallOrLess ? commonStyles.gapSmall : commonStyles.gapLarge,
          isScreenSmallOrLess && commonStyles.flexColumn,
        )}
      >
        <Button
          label='Contact Section Leader'
          borderOnHover
          fontSize='20px'
          icon={CustomIconName.ArrowCircleRight}
          onClick={() =>
            document
              .getElementById('section-contact')
              ?.scrollIntoView({ block: 'center', behavior: 'smooth' })
          }
          focusable={isVisible}
        />
        <Button
          label='Contact GSL'
          borderOnHover
          fontSize='20px'
          icon={CustomIconName.ArrowCircleRight}
          link={`${Page.Contact}#gsl`}
          focusable={isVisible}
        />
      </div>
    </>
  );
};

export default SectionDetailsSafeguarding;
