import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './pages/home/home';
import Layout from './components/layout/layout';
import NotFound from './pages/not-found/not-found';
import RouterScroll from './util/router-scroll';
import Contact from './pages/contact/contact';
import Squirrels from './pages/sections/squirrels/squirrels';
import Beavers from './pages/sections/beavers/beavers';

function App() {
  return (
    <BrowserRouter>
      <RouterScroll />
      <Routes>
        <Route path='/' element={<Layout />}>
          <Route index element={<Home />} />
          <Route path='contact' element={<Contact />} />
          <Route path='squirrels' element={<Squirrels />} />
          <Route path='beavers' element={<Beavers />} />
          <Route path='*' element={<NotFound />} />
        </Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
