import { StyleSheet } from 'aphrodite';
import { theme } from '../../styles/theme';

const cardStyles = StyleSheet.create({
  card: {
    borderRadius: theme.size.small,
  },
  fitContent: {
    width: 'fit-content',
  },
  nativeReset: {
    border: 'none',
    font: 'inherit',
    color: 'inherit',
    backgroundColor: theme.colour.white,
    textDecoration: 'none',
    textAlign: 'left',
  },
});

export default cardStyles;
