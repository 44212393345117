import { css } from 'aphrodite';
import React from 'react';
import homeIntroductionStyles from './home-introduction.styles';
import { commonStyles } from '../../../styles/common.styles';
import { useScreenSizeContext } from '../../../util/screensize.context';
import { media } from '../../../util/media';

const HomeIntroduction = () => {
  const { isScreenSmallOrLess, isScreenMediumOrLess, isScreenXSmall } =
    useScreenSizeContext();
  return (
    <div className={css(homeIntroductionStyles.parentContainer)}>
      <div className={css(homeIntroductionStyles.container)}>
        <h2
          className={css(
            isScreenMediumOrLess
              ? homeIntroductionStyles.headingMobile
              : homeIntroductionStyles.heading,
          )}
        >
          We&apos;ve been preparing young people with{' '}
          <span
            className={css(
              isScreenMediumOrLess
                ? homeIntroductionStyles.enhancedHeadingMobile
                : homeIntroductionStyles.enhancedHeading,
            )}
          >
            skills for life
          </span>{' '}
          for{' '}
          <span
            className={css(
              isScreenMediumOrLess
                ? homeIntroductionStyles.enhancedHeadingMobile
                : homeIntroductionStyles.enhancedHeading,
            )}
          >
            50 years
          </span>
        </h2>
        <div
          className={css(
            commonStyles.flex,
            homeIntroductionStyles.contentContainer,
            isScreenSmallOrLess
              ? homeIntroductionStyles.contentContainerSmall
              : isScreenMediumOrLess
                ? homeIntroductionStyles.contentContainerMedium
                : homeIntroductionStyles.contentContainerLarge,
          )}
        >
          <div
            className={css(
              commonStyles.flex,
              commonStyles.flexColumn,
              commonStyles.gapXXXSmall,
            )}
          >
            <h4
              className={css(
                homeIntroductionStyles.introductionText,
                isScreenXSmall
                  ? homeIntroductionStyles.introductionTextMobile
                  : homeIntroductionStyles.introductionTextLarger,
              )}
            >
              We are 2nd Rugeley, a group consisting of a <b>Squirrel Drey</b>,
              a <b>Beaver Colony</b>, a <b>Cub Pack</b>, a <b>Scout Troop</b>{' '}
              and an <b>Explorer Unit</b>. We have provided the young people of
              Rugeley and the surrounding areas the opportunity to develop and
              thrive for <b>50 years</b>, giving them the <b>skills for life</b>
              .
            </h4>
            <p>
              As Scouts, we encourage young people to do more, learn more and be
              more. Each week, we help over 100 young people aged 4-25 enjoy fun
              and adventure while developing the skills they need to succeed,
              now and in the future.
            </p>
            <p>
              We&apos;re talking about teamwork, leadership and resilience -
              skills that have helped Scouts become everything from teachers and
              social workers to astronauts and Olympians. We help young people
              develop and improve key life skills.
            </p>
            <p>
              We believe in bringing people together. We&apos;re proud to be the
              UK&apos;s largest mixed youth movement celebrating diversity and
              standing against intolerance. We&apos;re part of a worldwide
              movement, creating stronger communities and inspiring positive
              futures.
            </p>
          </div>
          <div
            className={css(
              isScreenSmallOrLess
                ? homeIntroductionStyles.imageContainerMobile
                : homeIntroductionStyles.imageContainer,
            )}
          >
            <img
              className={css(commonStyles.smooth)}
              src={media.images.index.introduction.src}
              width={
                isScreenSmallOrLess
                  ? '80%'
                  : isScreenMediumOrLess
                    ? '300px'
                    : '350px'
              }
              alt={media.images.index.introduction.alt}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeIntroduction;
