import React from 'react';
import { css } from 'aphrodite';
import Button from '../../components/button/button';
import notFoundStyles from './not-found.styles';
import { Link } from 'react-router-dom';
import Header from '../../components/header/header';
import { commonStyles } from '../../styles/common.styles';
import { media } from '../../util/media';
import { Page } from '../../types/navigation';
import { CustomIconName } from '../../components/custom-icon/custom-icons';

const NotFound = () => {
  return (
    <Header
      image={media.images.background.campfire}
      alwaysFullScreen
    >
      <div
        className={css(
          commonStyles.textWhite,
          notFoundStyles.contentContainer,
          commonStyles.fullHeight,
          commonStyles.fullWidth,
        )}
      >
        <h1 className={css(notFoundStyles.title)}>Not Found</h1>
        <h2 className={css(notFoundStyles.subtext)}>
          Scouts are natural born seekers, but even we couldn&apos;t find this
          page
        </h2>
        <div className={css(notFoundStyles.button)}>
          <Link to={Page.Home}>
            <Button
              label='Home'
              bold
              fontSize='20px'
              icon={CustomIconName.ArrowCircleRight}
              variant='secondary-black'
            />
          </Link>
        </div>
        <div className={css(commonStyles.marginTopLarge)}>Error Code: 404</div>
      </div>
    </Header>
  );
};

export default NotFound;
